import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 37 37"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      stroke: "currentColor",
      d: "M5.375 18.5h20.212c.972 0 1.433-.845 1.433-1.382 0-.537-.46-1.381-1.382-1.381H5.375l5.344-5.3c.38-.38.38-1.228 0-1.608-.38-.38-1.28-.38-1.66 0l-7.368 6.908c-.921.92-.921 1.842 0 2.763l7.829 7.829c.38.38 1.462.38 1.842 0 .38-.38.38-1.463 0-1.842L5.375 18.5ZM16.044 3.866c-.537 0-1.228-.947-1.228-1.484S15.506 1 16.044 1h15.555a4.861 4.861 0 0 1 4.862 4.861V31.14A4.861 4.861 0 0 1 31.599 36H16.044c-.537 0-1.228-.845-1.228-1.382 0-.537.69-1.483 1.228-1.483h15.555c1.177 0 1.996-.82 1.996-1.996V5.86c0-1.177-.819-1.995-1.996-1.995H16.044Z"
    }, null, -1)
  ])))
}
export default { render: render }